<template>
  <div class="footer">
      <span>
        联系电话：19709911833&nbsp;&nbsp;电子信箱：08232716@cumt.edu.cn&nbsp;&nbsp;中国矿业大学·E心向党社会实践团版权所有
      </span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    background: rgb(30,50,100);
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 16px;
}
</style>