<template>
  <header>
    <img src="../assets/CUMT-logo.png" alt="">
    <span>红色攻城狮 ——“E新向党”实践团</span>
  </header>
</template>

<script>
export default {

}
</script>

<style scoped>
  header {
    background: #f4f4f4;
    padding: 30px 0;
    text-align: center;
    height: 100px;
  }
  header img {
    width: 500px;
    height: 110px;
    display: inline;
  }
  header span {
    color: rgb(30,50,100);
    font-size: 50px;
    font-family: 楷体;
    margin-left: 50px;
  }
</style>