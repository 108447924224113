import { render, staticRenderFns } from "./WebHead.vue?vue&type=template&id=4978c628&scoped=true"
import script from "./WebHead.vue?vue&type=script&lang=js"
export * from "./WebHead.vue?vue&type=script&lang=js"
import style0 from "./WebHead.vue?vue&type=style&index=0&id=4978c628&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4978c628",
  null
  
)

export default component.exports