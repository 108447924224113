import { render, staticRenderFns } from "./CardComponent.vue?vue&type=template&id=6f1afe49&scoped=true"
import script from "./CardComponent.vue?vue&type=script&lang=js"
export * from "./CardComponent.vue?vue&type=script&lang=js"
import style0 from "./CardComponent.vue?vue&type=style&index=0&id=6f1afe49&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1afe49",
  null
  
)

export default component.exports